import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Accordion } from './components/accordion';

export const AccordionList = ({ data }) => {
    const [openedItem, setOpenedItem] = useState(data[0].title);

    return (
        <div>
            {data.map((item) => (
                <Accordion
                    key={item.title}
                    {...item}
                    setOpenedItem={setOpenedItem}
                    isOpen={openedItem === item.title}
                />
            ))}
        </div>
    );
};

AccordionList.propTypes = {
    data: arrayOf(
        shape({
            title: string.isRequired,
            text: string.isRequired,
            firstLinkHref: string,
            secondLinkHref: string,
            target: string,
        }),
    ).isRequired,
};
