import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { isGatsbyImage } from 'utilities';

import { AccordionList } from './components/accordions-list';

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
`;

const SWrapper = styled.div`
    margin-top: 3.75rem;
    display: flex;
    gap: 5rem;

    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
        gap: 4rem;
    `}
`;

const SImageCol = styled.div`
    flex: 0.45;
    display: flex;
    justify-content: end;

    ${CONSTANTS.MEDIA.max768`
        justify-content: center;
    `}
`;

const SCol = styled.div`
    flex: 0.55;
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
    text-align: center;
`;

const STextWrapper = styled.div`
    text-align: center;
    margin: 0 auto 3.75rem;
    max-width: 46.25rem;
`;

const SIconWrapper = styled.div`
    max-width: 28.125rem;
    height: 23rem;
    ${CONSTANTS.MEDIA.max768`
        height: 100%;
        width: 100%;
    `}
`;

export const AccordionContentSection = ({
    img,
    titleId,
    subtitleId,
    listData,
    alt,
}) => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(titleId);

    return (
        <Container>
            <STextWrapper>
                <SHeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={titleId} />
                </SHeaderSecond>
                {subtitleId && (
                    <SParagraph>
                        <FormattedMessage id={subtitleId} />
                    </SParagraph>
                )}
            </STextWrapper>
            <SWrapper>
                <SImageCol>
                    {isGatsbyImage(img) ? (
                        <GatsbyImage
                            alt={alt}
                            image={img.childImageSharp.gatsbyImageData}
                            imgStyle={{
                                objectFit: 'scale-down',
                                objectPosition: 'contain',
                                height: '100%',
                            }}
                        />
                    ) : (
                        <SIconWrapper>{img}</SIconWrapper>
                    )}
                </SImageCol>
                <SCol>
                    <AccordionList data={listData} />
                </SCol>
            </SWrapper>
        </Container>
    );
};
