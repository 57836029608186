import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { bool, func, string } from 'prop-types';
import CollapsedIcon from 'svgs/icons/ic-add-square.svg';
import OpenedIcon from 'svgs/icons/ic-minus-square.svg';

const SAccordionWrapper = styled.div`
    padding-bottom: 1.75rem;
    padding-left: 3rem;
    border-left: 2px solid
        ${({ isOpen }) =>
            isOpen ? 'var(--primary-color)' : 'var(--gray-color-70)'};
`;

const SAccordionHeader = styled.div`
    display: flex;
    gap: 0.875rem;
    align-items: center;
    cursor: pointer;
`;

const STitle = styled(HeaderThird)`
    font-size: 1.375rem;
    font-weight: 500;
`;

const SText = styled.p`
    margin-top: 0.5rem;
    a {
        color: var(--primary-color);
    }
`;

const SAccordionPanel = styled.div`
    max-height: ${({ isOpen }) => (isOpen ? '62.5rem' : 0)};
    transition: ${({ isOpen }) =>
        isOpen
            ? 'max-height 1s ease-in-out'
            : 'max-height 1s cubic-bezier(0,1,0,1)'};
    overflow: hidden;
    display: block;
    padding-left: 2.375rem;
`;

export const Accordion = ({
    title,
    text,
    isOpen,
    setOpenedItem,
    firstLinkHref,
    secondLinkHref,
    target,
}) => {
    const handleClick = () => {
        setOpenedItem(title);
    };

    return (
        <SAccordionWrapper isOpen={isOpen}>
            <SAccordionHeader onClick={handleClick}>
                {isOpen ? <OpenedIcon /> : <CollapsedIcon />}
                <STitle>
                    <FormattedMessage id={title} />
                </STitle>
            </SAccordionHeader>
            <SAccordionPanel isOpen={isOpen}>
                <SText>
                    <FormattedMessage
                        id={text}
                        values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            firstLink: (chunks) => (
                                <a
                                    href={firstLinkHref}
                                    target={target}
                                    rel="noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                            secondLink: (chunks) => (
                                <a
                                    href={secondLinkHref}
                                    target={target}
                                    rel="noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </SText>
            </SAccordionPanel>
        </SAccordionWrapper>
    );
};

Accordion.propTypes = {
    title: string.isRequired,
    text: string.isRequired,
    setOpenedItem: func.isRequired,
    isOpen: bool.isRequired,
    firstLinkHref: string,
    secondLinkHref: string,
    target: string,
};

Accordion.defaultProps = {
    target: '_blank',
};
